//@ts-check
import { useAuthenticator } from "@/pinia/authenticator"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n-composable"
import { useToast } from "./toast"
import { sendSMS } from "@/api/system"


export const useRegisterPhone = () => {
  const authenticator = useAuthenticator()
  const enabled = ref(true)
  const { t } = useI18n()
  const rawCaptcha = ref('')
  const time = ref(60)
  const logining = ref(false)
  const { Toast } = useToast()
  const phone = ref({
    phoneNumber: '',
    rawPhoneNumber: '',
  })
  const sending = ref(false)

  const buttonTitle = computed(() => {
    if (sending.value) {
      return t('sending')
    }

    if (time.value === 60) {
      return t('getCaptcha')
    } else {
      return t('timeleft', { time: time.value })
    }
  })

  const captcha = computed({
    get () { return rawCaptcha.value.trim().toUpperCase() },
    set (val) { rawCaptcha.value = val }
  })

  const login = () => {
    const credentials = {
      phoneNumber: `+${phone.value.phoneNumber}`,
      captcha: captcha.value,
      rawPhoneNumber: phone.value.rawPhoneNumber,
    }
    logining.value = true
    authenticator.loginPhone(credentials)
    logining.value = false
  }

  const startCount = () => {
    const intervalId = setInterval(() => {
      time.value -= 1

      if (time.value === 0) {
        enabled.value = true
        time.value = 60
        clearInterval(intervalId)
      }
    }, 1000)
  }

  const send = async () => {
    try {
      if (!enabled.value) {
        return
      }
      if (sending.value) {
        return
      }

      sending.value = true
      enabled.value = false

      await sendSMS(`+${phone.value.phoneNumber}`, phone.value.rawPhoneNumber)
      startCount()
      Toast({
        message: t('sendOK'),
      }, 'ok')
    } catch (err) {
      Toast({
        message: err.message,
      })
      enabled.value = true
    } finally {
      sending.value = false
    }
  }

  return {
    phone,
    captcha,
    enabled,
    time,
    logining,
    sending,
    login,
    send,
    buttonTitle,
  }
}
